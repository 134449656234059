import React from 'react';

export const OrderContext = React.createContext({
    order: null,
    activeBasket: false,
    isReloading: false,
    addCompositionToCart: (composition) => {},
    checkUserOpenOrder: () => {},
    createOrder: () => {},
    getOrCreateOrder: (orderId, sendAddToCart=true) => {},
    getShippingCost: (countryName) => {},
    handleApplyCoupon: () => {}, 
    handleApplyServiceFee: () => {},
    handleConfirmOrder: () => {},
    handleRemoveCoupon: () => {},
    hasSingleDigitalVoucher: (compositions) => {},
    hasSingleExchangeComposition: (compositions) => {},
    // patchOrder: () => {},
    reloadOrder: () => {},
    removeComposition: () => {},
    setCurrentOrder: () => {},
    setIsReloading: () => {},
    updateItemHangingSystem: () => {},
    updateItemLUTFilter: () => {},
    updateItemPaper: () => {},
    updateOrder: () => {},
    updateShippingCountry: () => {},
    updateShippingAddress: () => {},
});

export function useOrder() {
    return React.useContext(OrderContext);
}
