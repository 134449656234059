import React from "react";

export const WorkspaceContext = React.createContext({
    hoveredFrame: null,
    nearestFrame: null,
    isGesture: false,
    isDragging: false,
    isPanning: false,
    isSelecting: false,
    isTransforming: false,
    isMultiTouching: false,
    cameraRef: null,
    frameOrbitControls: false,
    stopCameraAnimation: false,
    showActiveFrameCropHint: true,
    updateIconSize: false,
    workspaceWidth: 0,
    workspaceHeight: 0,
    workspaceMessagesSectionHeight: 0,
    workspaceToolsSectionHeight: 0,
    workspaceToolsRelativeHeight: 0,
    workspaceHeightRef: 0,
    workspaceWidthRef: 0,
    cameraOffsetY: 0,
    cameraOffsetYRef: 0,
    cameraOffsetZ: 0,
    cameraOffsetZRef: 0,
    isAnimating: true,
    mobileToolbar: null,
    cropZoomSlider: null,
    communicationBars: null,
    dotsSection: null,
    showTools: true,
    isEditMode: false,
    showEditModeFramesMenu: false,
    editModeFramesMenuPosition: [0, 0],
    editModeFramesMenuSize: 0,
    editModeFramesMenuCount: 0,
    gridOffset: [0, 0],
    framesClipboard: null,
    openNewsletterTooltip: false,
    setShowEditModeFramesMenu: () => {},
    setEditModeFramesMenuPosition: () => {},
    setEditModeFramesMenuSize: () => {},
    adjustEditMenuPosition: () => {},
    setEditModeFramesMenuCount: () => {},
    openEditMode: () => {},
    closeEditMode: () => {},
    saveEditedComposition: () => {},
    updateEditedFrames: () => {},
    setShowTools: () => {},
    setMobileToolbar: () => {},
    setCropZoomSlider: () => {},
    setCommunicationBars: () => {},
    setHoveredFrame: () => {},
    setNearestFrame: () => {},
    setIsGesture: () => {},
    setFrameOrbitControls: () => {},
    setStopCameraAnimation: () => {},
    setShowActiveFrameCropHint: () => {},
    setUpdateIconSize: () => {},
    screenToWorld: () => {},
    worldToScreen: () => {},
    adjustIconSize: () => {},
    handleCropSliderChange: () => {},
    calculateCameraOffsetY: () => {},
    calculateCameraExtraY: () => {},
    setCameraOffsetY: () => {},
    setCameraOffsetZ: () => {},
    setIsAnimating: () => {},
    setIsEditMode: () => {},
    calculateCameraPosition: () => {},
    addFramesToSelection: () => {},
    detectFrame: () => {},
    findNearestFrame: () => {},
    checkFrameCollisions: () => {},
    findClosestFit: () => {},
    findClosestFitForGroup: () => {},
    addFrame: () => {},
    deleteFrames: () => {},
    duplicateFrames: () => {},
    rotateFrames: () => {},
    flipFrames: () => {},
    flipComposition: () => {},
    copyFramesToClipboard: () => {},
    pasteFramesFromClipboard: () => {},
});

export function useWorkspace() {
    return React.useContext(WorkspaceContext);
}
