import React from "react";

export const CompositionTemplateContext = React.createContext({
    currentTemplate: null,
    compositionTemplatesLoading: false,
    BASIC_TEMPLATE: {},
    templates: [],
    filterCategoryId: null,
    filterFrameCounts: null,
    filterPrice: { min: 0, max: Infinity },
    filterWallSize: { width: 0, height: 0 },
    isCategoryStairs: false,
    isFetchingTemplates: false,
    filteredOutCount: null,
    filteredTemplatesCount: null,
    flipComposition: false,
    numPages: null,
    currentPage: null,
    templatesPerPage: null,
    setFlipComposition: () => {},
    setCurrentPage: () => {},
    setFilterCategoryId: () => {},
    setFilterFrameCount: () => {},
    setFilterPrice: () => {},
    setFilterPriceApplied: () => {},
    setFilterPriceCurrency: () => {},
    setCurrentTemplate: () => {},
    getCompositionTemplate: () => {},
    pickRandomTemplate: () => {},
    getAvgTemplatePrice: () => {},

    setPassepartoutPreset: () => {},
    
    scrollPosition: 0,
    setScrollPosition: () => {},
});


export function useCompositionTemplates() {
    return React.useContext(CompositionTemplateContext);
};
