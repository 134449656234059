export const delay = async (ms) => new Promise((res) => setTimeout(res, ms));
// export const isTouchDevice = () => {
//     return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
// };

export const getQueryParams = (searchParams) => ({
    height: searchParams.get('height'),
    width: searchParams.get('width'),
    category: searchParams.get('category'),
    modelViewer: searchParams.get('model_viewer'),
    stats: searchParams.get('stats'),
    orderId: searchParams.get('order_id'),
    compositionTemplateHash: searchParams.get('ct_hash'),
    drawer: searchParams.get('drawer'),
    couponCode: searchParams.get('code'),
    size: searchParams.get('size'),
});

export const generateRandomId = () => {
    return Math.random().toString(36).substr(2, 8);
};

export const generateRandomFileName = (fileName) => {
    const fn = fileName;
    return fn.replace(/\.[^/.]+$/, "") + "_" + generateRandomId() + "." + fn.split(".").pop();
};

export const calculateWallWidth = (wallShape) => {
    if (!wallShape) {
        return 0;
    }
    let minWidth = Infinity;
    let maxWidth = -Infinity;

    wallShape.forEach((point) => {
        const x = point[0];

        if (x < minWidth) {
            minWidth = x;
        }
        if (x > maxWidth) {
            maxWidth = x;
        }
    });

    return maxWidth - minWidth;
};

export const calculateWallHeight = (wallShape) => {
    if (!wallShape) {
        return 0;
    }
    
    let minHeight = Infinity;
    let maxHeight = -Infinity;

    wallShape.forEach((point) => {
        const y = point[1];
        if (y < minHeight) {
            minHeight = y;
        }
        if (y > maxHeight) {
            maxHeight = y;
        }
    });

    return maxHeight - minHeight;
};

export const hasSameProperty = (arrayOfObjects, property) => {
    let match = false, value = null;

    match = arrayOfObjects?.every((object, i, arr) =>
        i === 0 || object[property] === arr[i - 1][property]
    );
    value = match ? (arrayOfObjects[0] ? arrayOfObjects[0][property] : null) : null;

    return { match, value };
};

export const convertHexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export const convertRgbToHex = (r, g, b) => {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const getWidthFromShape = (wallShape) => {
    return wallShape?.[2]?.[0] / 10;
};

export const getHeightFromShape = (wallShape) => {
    return wallShape?.[2]?.[1] / 10;
};

export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

export const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const generateCombinations = (options) => {
    let result = [];

    function generateHelper(path, index) {
        if (index === options.length) {
            result.push([...path]);
            return;
        }
        for (let option of options[index]) {
            path.push(option);
            generateHelper(path, index + 1);
            path.pop();
        }
    }
    generateHelper([], 0);
    return result;
}

const TRUSTPILOT_LOCALES = [
    "da-DK",
    "de-DE",
    "en-GB",
    "es-ES",
    "fi-FI",
    "fr-FR",
    "it-IT",
    "nb-NO",
    "nl-NL",
    "pl-PL",
    "pt-PT",
    "sv-SE",
    "en-AU",
    "en-IE",
    "de-AT",
    "de-CH",
    "fr-BE",
    "nl-BE",
    "ja-JP",
    "pt-BR",
    "en-US",
    "en-CA",
    "en-NZ",
]

const TRUSTPILOT_LANGUAGE_LOCALE_MAPPING = {
    "da": "da-DK",
    "de": "de-DE",
    "en": "en-GB",
    "es": "es-ES",
    "fi": "fi-FI",
    "fr": "fr-FR",
    "it": "it-IT",
    "nb": "nb-NO",
    "nl": "nl-NL",
    "pl": "pl-PL",
    "pt": "pt-PT",
    "sv": "sv-SE",
    "ja": "ja-JP",
}

export const getTrustpilotLocale = (language, country) => {
    console.log('language', language, 'country', country);
    const defaultLocale = 'en-GB';
    const locale = `${language}-${country}`;
    return TRUSTPILOT_LOCALES.includes(locale) ? locale : TRUSTPILOT_LANGUAGE_LOCALE_MAPPING[language] || defaultLocale;
}

export const addOneDayToDate = (date) => {
    date.setDate(date.getDate() + 1);
    return date;
};
