import React, { createContext, useContext } from 'react';

export const AppContext = createContext({
    isMobile: false,
    smallHeightDevice: false,
    isHandheldDevice: false,
    isHighDensity: false,
    isSafari: false,
});

export function useAppContext() {
    return useContext(AppContext);
}
